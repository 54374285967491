import React, { useEffect, useState } from "react";
import "./Noticias.css";
import NoticiaContent from "./NoticiaContent";
import { Link } from "react-router-dom";

// Se você utiliza variável de ambiente para sua rota base:
const main = process.env.REACT_APP_MAIN;

function Noticias({ flag }) {
  const [noticias, setNoticias] = useState([]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Ao montar o componente, faz a requisição para sua API
  useEffect(() => {
    fetch("https://terciotinoco.com.br/api/novidades")
      .then(response => response.json())
      .then(data => {
        // Ordena por data caso a API não ordene automaticamente (necessário para pegar os 3 últimos corretamente)
        const noticiasOrdenadas = data.sort((a, b) => new Date(b.data) - new Date(a.data));
        setNoticias(noticiasOrdenadas);
      })
      .catch(err => {
        console.error("Erro ao buscar novidades:", err);
      });
  }, []);

  // Se a flag for 'home', exibir apenas os três últimos
  const noticiasExibidas = flag === 'home' ? noticias.slice(0, 3) : noticias;

  return (
    <>
      {flag !== 'home' && <div className="header-bg" />}

      <div className="noticias-wrapper">
        <div className="noticias-container">
          <div className="noticias-content">
            <h2>Virou notícia</h2>
            <p>Confira aqui o que Tércio fez que saiu na mídia</p>
          </div>

          {/* Se "flag" não for 'home' e houver pelo menos 1 notícia, exibe a primeira em destaque */}

          <div className="noticias-cards">
            {noticiasExibidas.map((noticia) => (
              <div key={noticia.id} className="noticia-card">
                {/* Exemplo de imagem, se existir 'imagem' no objeto */}
                {noticia.imagem && (
                  <img
                    src={`https://terciotinoco.com.br/api/novidades/imagem/${noticia.imagem}`}
                    alt={noticia.titulo}
                    className="noticia-images"
                  />
                )}
                <div className="textos-news">
                  <h3>{noticia.titulo}</h3>
                  <p>{noticia.texto}</p>
                  <small>{noticia.data.split("-").reverse().join("/")} - Vereador Tércio Tinoco</small>

                </div>
              </div>
            ))}
          </div>

          {flag === 'home' && (
            <div className="redirect">
              <Link onClick={scrollToTop} to={main + "virounoticia"}>
                VEJA MAIS NOTÍCIAS
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Noticias;
